var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: _vm.$utilities.getRippleOptions("light"),
              expression: "$utilities.getRippleOptions('light')",
            },
          ],
          class: _vm.classes,
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.content.label) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }