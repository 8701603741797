<template>
  <button
    v-if="content"
    :class="classes"
    type="button"
    v-ripple="$utilities.getRippleOptions('light')"
    @click.prevent="onClick">
    {{ content.label }}
  </button>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    content: {
      type: Object,
      default: undefined
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const classes = computed(() => [
      'dp-tab',
      props.isActive ? 'dp-tab--active' : false
    ]);

    const onClick = () => {
      context.root.$router.push({ name: props.content?.to });
    };

    return {
      onClick,
      classes
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-tab {
  display: block;
  padding: 16px 4px 14px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: variables.$dpGrey88;
  font-family: Oswald;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  transition-property: border color;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  &--active {
    border-bottom-color: variables.$dpBlack01;
    color: variables.$dpBlack01;
  }
}
</style>
